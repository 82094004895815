import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { landingTheme } from 'common/src/theme/landing';
import { ResetCSS } from 'common/src/assets/css/style';
import { useStaticQuery, graphql } from 'gatsby';
import { Modal } from '@redq/reuse-modal';
import '@redq/reuse-modal/es/index.css';
import CookieConsent from 'react-cookie-consent';

// Sections
import Header from '../containers/Landing/sections/Header';
import VideoWithPromo from '../containers/Landing/sections/VideoWithPromo';
import FeaturesList from '../containers/Landing/sections/FeaturesList';
import IntroWithImage from '../containers/Landing/sections/IntroWithImage';
import BenefitsWithForm from '../containers/Landing/sections/BenefitsWithForm';
import StaticFooter from '../containers/Landing/sections/StaticFooter';

// Styles
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/Landing/landing.style';

// Components
import SEO from '../components/seo';

// Smooth Scroll
if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]');
}

export default () => {
  const DataQuery = useStaticQuery(graphql`
    query {
      eComm360ShopifyV2Json {
        C {
          SEO {
            seoTitle
            seoDesc
          }
          VideoWithPromo {
            vwpLogo {
              publicURL
            }
            vwpHeading
            vwpText
            vwpButton
          }
          FeaturesList {
            flDataFeatures {
              title
              description
              icon {
                publicURL
              }
            }
          }
          IntroWithImage {
            iwiHeading
            iwiText
            iwiImage {
              publicURL
            }
          }
          BenefitsWithForm {
            bwfHeading
            bwfFormHeading
            bwfFormLogo {
              publicURL
            }
            bwfFormPageType
            bwfFormLink
            bwfFormButton
            bwfDataBenefits {
              content
            }
          }
          StaticFooter {
            footerAbout
            dataAwards {
              content
            }
          }
        }
      }
    }
  `);

  // Data
  const Data = DataQuery.eComm360ShopifyV2Json.C;

  // SEO
  const seoTitle = Data.SEO.seoTitle;
  const seoDesc = Data.SEO.seoDesc;

  // VideoWithPromo
  const vwpLogo = Data.VideoWithPromo.vwpLogo.publicURL;
  const vwpHeading = Data.VideoWithPromo.vwpHeading;
  const vwpText = Data.VideoWithPromo.vwpText;
  const vwpButton = Data.VideoWithPromo.vwpButton;

  // FeaturesList
  const flDataFeatures = Data.FeaturesList.flDataFeatures;

  // IntroWithImage
  const iwiHeading = Data.IntroWithImage.iwiHeading;
  const iwiText = Data.IntroWithImage.iwiText;
  const iwiImage = Data.IntroWithImage.iwiImage.publicURL;

  // BenefitsWithForm
  const bwfHeading = Data.BenefitsWithForm.bwfHeading;
  const bwfFormHeading = Data.BenefitsWithForm.bwfFormHeading;
  const bwfFormLogo = Data.BenefitsWithForm.bwfFormLogo.publicURL;
  const bwfFormPageType = Data.BenefitsWithForm.bwfFormPageType;
  const bwfFormLink = Data.BenefitsWithForm.bwfFormLink;
  const bwfFormButton = Data.BenefitsWithForm.bwfFormButton;
  const bwfDataBenefits = Data.BenefitsWithForm.bwfDataBenefits;

  // StaticFooter
  const footerAbout = Data.StaticFooter.footerAbout;
  const dataAwards = Data.StaticFooter.dataAwards;

  return (
    <ThemeProvider theme={landingTheme}>
      <Fragment>
        <SEO title={seoTitle} description={seoDesc} />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Header />
          <VideoWithPromo
            vwpLogo={vwpLogo}
            vwpHeading={vwpHeading}
            vwpText={vwpText}
            vwpButton={vwpButton}
          />
          <FeaturesList flDataFeatures={flDataFeatures} />
          <IntroWithImage
            iwiHeading={iwiHeading}
            iwiText={iwiText}
            iwiImage={iwiImage}
          />
          <BenefitsWithForm
            bwfHeading={bwfHeading}
            bwfDataBenefits={bwfDataBenefits}
            bwfFormLogo={bwfFormLogo}
            bwfFormHeading={bwfFormHeading}
            bwfFormPageType={bwfFormPageType}
            bwfFormLink={bwfFormLink}
            bwfFormButton={bwfFormButton}
          />
          <StaticFooter footerAbout={footerAbout} dataAwards={dataAwards} />
          <CookieConsent
            location="bottom"
            buttonText="Accept"
            style={{ background: '#2b3d4d' }}
            buttonStyle={{
              color: '#2b3d4d',
              background: '#e6ff0f',
              fontSize: '13px',
              borderRadius: '4px',
            }}
            cookieName="gatsby-plugin-gdpr-cookies"
          >
            We are using cookies to give you the best experience on our website.
            <br />
            You can find out more about which cookies we are using or switch
            them off in settings. To find out more, see our
            <a
              href="https://cybertonica.com/privacy-policy?ref=discover"
              className="DefaultPrivacyLink"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            .
          </CookieConsent>
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
